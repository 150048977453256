<template>
  <div class="">
    <h1 v-if="this.w <450"  :style="{textAlign: 'left',lineHeight:'30px', marginLeft:'5%',width:'85%',
    color:'#0A6338',fontSize:'32px' ,marginTop:'3%' ,font: 'normal normal bold ', letterSpacing: '0px'   }">
      <span :style="{fontWeight:'bold'}" >
        Vi gör det enkelt
      </span>
      att hitta reparatörer av alla slag i hela landet
    </h1>

    <h1 v-if="this.w >450" :style="{textAlign: 'left',lineHeight:'40px', marginLeft:'5.4%',width:'75%',
    color:'#0A6338',fontSize:'42px' ,marginTop:'3%' ,font: 'normal normal bold ', letterSpacing: '0px'   }">
      <span :style="{fontWeight:'bold'}" >
        Vi gör det enkelt
      </span>
      att hitta reparatörer av alla slag i hela landet
    </h1>
    <div :style="{width:'100%', height:'360px',  display:'flex',marginTop:'3%',borderRadius:'74px'}">
      <h2 v-if="this.w >450" :style="{textAlign: 'left',height:'104px',width:'84%', fontSize:'20px' ,marginTop:'0%', marginLeft:'5.4%' }">
        Enjord är en plattform som gör det lättare för folk att hitta reparatörer över hela landet anpassad efter det de behöver hjälp med. <br><br>
        Vi drivs av att förlänga livslängden på produkter och vill göra det enklare för folk att ha sina produkter några år till.<br>

        Vårt mål är att göra det enklare och billigare att reparera sina produkter genom att koppla samman dig med kvalificerade reparatörer som kan förlänga livslängden på dina produkter.<br>
        <br>Vi tror på att ta ansvar för våra konsumtionsval och minska onödig avfallshantering. Genom att välja att reparera istället för att köpa nytt, kan vi spara resurser, minska koldioxidutsläpp och skapa en mer hållbar framtid.<br>
        Så oavsett om det är din favorittelefon, dina kära möbler eller bara ett köksredskap du tycker är onödigt att slänga, vill vi hjälpa dig hitta rätt reparatör och förlänga livslängden på dina ägodelar. <br>
        <br>Tillsammans kan vi göra en positiv skillnad för både vår plånbok och planeten.

        <br><br> Är du en reparatör och är intresserad av att ansluta dig till vår plattform så registrera dig
        <a  href='https://www.enjord.com/register' :style="{ color:'black', textDecoration:'underline'}"  >
                     här
                   </a>eller skicka ett e-postmeddelande till oss på info@enjord.com så återkommer vi.
        </h2>

      <div v-if="this.w <450" :style="{textAlign: 'left',height:'104px',width:'92%', fontSize:'18px' ,marginTop:'2%', marginLeft:'5.4%' }">
        Enjord är en plattform som gör det lättare för folk att hitta reparatörer över hela landet anpassad efter det de behöver hjälp med. <br><br>
        Vi drivs av att förlänga livslängden på produkter och vill göra det enklare för folk att ha sina produkter några år till.<br>
        <br> Är du en reparatör och vill finnas på våra hemsida så registrera dig <a  href='https://www.enjord.com/register' :style="{ color:'black', textDecoration:'underline'}"  >
                     här
                   </a> eller skicka ett e-postmeddelande till oss på info@enjord.com så återkommer vi.
      </div>
      <!-- <div v-if="this.w >450" :style="{width:'30%', height:'254px',marginLeft:'10%', backgroundColor:'#0A6338', borderRadius:'74px'}">
          <img :src= "`${publicPath}`+ 'repariflat.jpg'" alt="repariflat" :style="{height:'90%',borderRadius:'74px', marginTop:'2%', width:'80%'}">
      </div> -->
    </div>
    <div v-if="this.w >450"  :style="{width:'100%', marginTop:'3%'}">
      <div :style="{height:'83px',color: '#0A6338', fontWeight:'bold', fontSize:'34px'}">
         Teamet
      </div>
      <div :style="{width:'100%', height:'500px', display:'flex'}">
        <div :style="{height:'735px', width:'35%', marginLeft:'10%'}">
          <img :src= "`${publicPath}`+ 'abmoh.jpg'" alt="abbe" :style="{height:'308px',borderRadius:'74px', width:'308px'}">
          <div :style="{marginTop:'6%', fontSize:'18px'}">
              Abdirahman Mohamed har erfarenhet som investeringsanalytiker inom Venture Capital och har en kandidatexamen i ekonomi från Stockholms universitet. Han har tidigare arbetat som konsult på Klarna.
          </div>
        </div>
        <div :style="{height:'735px', width:'35%',marginLeft:'8%'}">
          <img :src= "`${publicPath}`+ 'jonte.jpg'" alt="jonte"   :style="{height:'308px',borderRadius:'74px', width:'308px'}">
          <div :style="{marginTop:'6%', fontSize:'18px'}">
              Jonathan Cygnaeus har en konsultbakgrund och jobbat med allt från miljö till IT. Han har en masterexamen i teknisk fysik och elektroteknik med studier vid Linköpings universitet och University of Oxford.
          </div>
        </div>
      </div>
    </div>
    <div  v-if="this.w >450" :style="{height:'220px', fontSize:'18px',marginTop:'3%' , paddingTop:'2%',width:'70%', marginLeft:'15.25%',backgroundColor: '#0A6338',color:'white', borderRadius:'74px'}">
        Skicka ett meddelande till oss på info@enjord.com om du är intresserad av att ansluta dig till vår plattform.
        <br>Vi ser fram emot att höra från dig!<br><br>
        Besök oss gärna på våra sociala medier: &nbsp; &nbsp;
          <a href='https://www.instagram.com/enjord_app/'   >
            <b-icon :style="{height:'25px',width:'25px',color:'white'}" icon="instagram"   />
           </a>
           &nbsp; &nbsp;
           <a href='https://se.linkedin.com/company/enjordreparator'  >
             <b-icon :style="{height:'25px',width:'25px',color:'white'}" icon="linkedin"   />
           </a><br><br>

             Vi är även medlemmar i CirEko:
             <a href='https://cireko.se/medlemmar/enjord/'   >
               <img @click="to_explore()" class="image_logo_large"  alt="cireko"  :src= "`${publicPath}`+ 'cireko.jpg'">
             </a>


    </div>
    <div :style="{height:'90px'}">

    </div>
    <!-- <bottombar/> -->
  </div>
</template>
<script>
//  import Navigation from '../components/Navigation.vue';
// import bottombar from '../components/bottombar.vue';
  export default {


    data:function(){
      return{
        user:0,
        publicPath: process.env.BASE_URL +'',
        w:0
      }
    },
    components:{
      //Navigation,
      // bottombar
    },
    mounted(){
       this.w = window.screen.width
    }
}
</script>

<style lang="css" scoped>
.image_logo_large{
  max-height: 30px;
  cursor:pointer;
  margin-top:0%;
  margin-left: 1%;
  max-width: 50px
}
</style>
